<template>
  <div>{{ v }}</div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
  },
  computed: {
    v() {
      const d = new Date(this.value);
      return (
        d.toLocaleDateString("ru-RU") + " " + d.toLocaleTimeString("ru-RU")
      );
    },
  },
};
</script>