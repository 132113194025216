export default {
    appName: "kazanstroi",
    baseUrl: "https://api.fundamental.su/api",
    websocetUrl: "wss://api.fundamental.su/data",
    imageUrl: "https://api.fundamental.su/api/image/download/",
    fileUrl: "https://api.fundamental.su/api/file/download/",
    recordUrl: "https://api.fundamental.su/",
    mainMenu: [{
            auth: 1,
            name: "Главная",
            icon: "fas fa-border-all",
            route: {
                name: "index",
            },
        },
        {
            auth: 1,
            name: "Задачи",
            icon: "fas fa-tasks",
            route: {
                name: "tasks",
            },
        },
        {
            auth: 20,
            name: "Объекты",
            icon: "far fa-building",
            route: {
                name: "objects",
            },
        },
        {
            auth: 20,
            name: "Студии",
            icon: "fas fa-home",
            route: {
                name: "rooms",
            },
        },
        {
            auth: 10,
            name: "Строительство",
            icon: "fas fa-hard-hat",
            route: {
                name: "buildings",
            },
        },
        {
            auth: 20,
            name: "Клиенты",
            icon: "fas fa-user-friends",
            route: {
                name: "clients",
            },
        },
        {
            auth: 20,
            name: "Продажи",
            icon: "far fa-handshake",
            route: {
                name: "sales",
            },
        },
        {
            auth: 30,
            name: "Финансы",
            icon: "fas fa-chart-line",
            route: {
                name: "finance",
            },
        },
        {
            auth: 20,
            name: "Объявления",
            icon: "fab fa-adn",
            route: {
                name: "ads",
            },
        },
        {
            auth: 30,
            name: "Разговоры",
            icon: "fas fa-phone-volume",
            route: {
                name: "records",
            },
        },
        {
            auth: 10,
            name: "Инфо",
            icon: "far fa-question-circle",
            route: {
                name: "info",
            },
        },
        {
            auth: 1000,
            name: "Настройки",
            sublist: [{
                    route: {
                        name: "users",
                    },
                    name: "Пользователи",
                    icon: "fas fa-user-cog",
                    auth: 1000,
                },
                {
                    route: {
                        name: "vendors",
                    },
                    name: "Поставщики",
                    icon: "fas fa-sink",
                    auth: 1000,
                },
                {
                    route: {
                        name: "info-admin",
                    },
                    name: "Инфо",
                    icon: "far fa-question-circle",
                    auth: 1000,
                },
            ]
        },
    ]
}