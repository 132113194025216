<template>
  <v-chip :color="el.color" dark>{{ el.text }}</v-chip>
</template>

<script>
import { modelOptions } from "../../mixings";
export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number],
    options: Array,
  },
  computed: {
    el() {
      let el = this.getElementByValue(this.value);
      if (el.text && el.color) return el;
      else
        return {
          color: "#555",
          text: "Не задано",
        };
    },
  },
  methods: {
    getElementByValue(value) {
      return this.options.find((x) => x.value == value)
        ? this.options.find((x) => x.value == value)
        : {};
    },
  },
};
</script>