import fillForm from "./fillForm"

export default {
    mixins: [fillForm],
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        updateData(id) {
            this.resetFrom();
            this.fillForm();
            this.fetchData(id);
        },
        async fetchData(id) {
            if (id) {
                this.loading = true;
                let response = await this.$axios.get(this.api + "/" + id);
                this.loading = false;
                for (const name in response.data.data) {
                    this.$set(this.data, name, response.data.data[name]);
                }
                this.afterFetchData(response)
            }
        },
        afterFetchData(response) { }
    }
}