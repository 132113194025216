<template>
  <v-row>
    <v-simple-table style="width: 100%">
      <tbody>
        <tr v-for="(el, key) in items" :key="key">
          <td class="text-left">
            <a :href="$root.config.fileUrl + el.file" target="_blank">
              {{ el.name }}
            </a>
          </td>
          <td width="10px">
            <v-btn
              :href="$root.config.fileUrl + el.file"
              target="_blank"
              class="mx-2"
              fab
              dark
              small
              color="error"
              title="Скачать"
            >
              <v-icon dark> mdi-download </v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-row>
</template>

<script>
export default {
  props: {
    value: [String, Number],
  },
  computed: {
    items: {
      get() {
        if (this.value) {
          try {
            return JSON.parse(this.value);
          } catch (error) {}
        }
        return [];
      },
    },
  },
};
</script>