var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-dialog-window',{attrs:{"value":_vm.value},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Входящий звонок: "+_vm._s(_vm.phone))]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('answer')}}},[_vm._v("Ответить")]),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"error"},on:{"click":function($event){return _vm.closeWindow()}}},[_vm._v("Отклонить")])]},proxy:true}])},[(_vm.data.id)?_c('div',[_c('a-form-view',{staticClass:"mb-10",attrs:{"value":_vm.data,"model":[
        _vm.model[0],
        _vm.model[1],
        _vm.model[2],
        _vm.model[3],
        _vm.model[4],
        _vm.model[5],
        _vm.model[6],
        _vm.model[7] ]}}),_c('div',{staticClass:"a-btn-group"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'clients_view', params: { id: _vm.data.id } })}}},[_vm._v("Открыть")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.showEditDialogFun(_vm.data.id)}}},[_vm._v("Редактировать")])],1)],1):_c('div',[_c('div',{staticClass:"mb-10"},[_c('h2',[_vm._v("Клиент не найден")])]),_c('div',{staticClass:"a-btn-group"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.createNew()}}},[_vm._v("Создать нового клиента")])],1)]),_c('edit-dialog',{attrs:{"id":_vm.idEdit,"api":_vm.url,"defaults":{ phone1: _vm.phone }},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }