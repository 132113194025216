<template>
  <div class="py-1">
    <v-card>
      <v-card-title>
        {{ model.title }}
        <v-spacer></v-spacer>
        <div class="text-right pa-4">
          <v-btn class="ma-1" @click="add()" color="primary">Добавить</v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <div>
          <v-row>
            <v-simple-table style="width: 100%">
              <tbody>
                <tr v-for="(el, key) in items" :key="key">
                  <td class="text-left">
                    <a :href="$root.config.fileUrl + el.file" target="_blank">
                      {{ el.name }}
                    </a>
                  </td>
                  <td width="10px">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="error"
                      title="Удалить"
                      @click.prevent="remove(key)"
                    >
                      <v-icon dark> mdi-close </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogFile" hide-overlay persistent max-width="600">
      <v-card>
        <v-card-title>Загрузка файлов</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <v-file-input
            multiple
            outlined
            chips
            label="Выберите файл"
            type="file"
            v-model="uploadsFiles"
          ></v-file-input>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="uploadsFiles ? false : true"
            :loading="fileLoading"
            color="primary"
            @click="upload()"
            >Загрузить</v-btn
          ><v-btn @click="dialogFile = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      uploadsFiles: null,
      dialogFile: false,
      fileLoading: false,
    };
  },
  computed: {
    items: {
      get() {
        let d;
        try {
          d = JSON.parse(this.value);
        } catch (error) {
          d = [];
        }
        return d;
      },
      set(v) {
        this.$emit("input", JSON.stringify(v));
      },
    },
  },
  methods: {
    remove(key) {
      let e = this.items;
      e.splice(key, 1);
      this.items = e;
    },
    add() {
      this.uploadsFiles = null;
      this.dialogFile = true;
      this.fileLoading = false;
    },
    async upload() {
      this.fileLoading = true;
      let promises = [];
      for (let file of this.uploadsFiles) {
        promises.push(this.uploadFile(file));
      }
      await Promise.all(promises);
      this.fileLoading = false;
      this.dialogFile = false;
    },
    uploadFile(file) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        // file.uploadStatus = 0;
        this.$axios
          .post("/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            // onUploadProgress: (progressEvent) => {
            //   file.uploadStatus = parseInt(
            //     (progressEvent.loaded / progressEvent.total) * 100
            //   );
            // },
          })
          .then((response) => {
            let data = response.data.data.file;
            let el = this.items;
            if (!Array.isArray(el)) el = [];
            el.push({ file: data, name: file.name });
            this.items = el;
            resolve();
          })
          .catch(function (error) {
            console.log(error);
            // this.$root.$emit("show-info", {
            //   text: "Error: " + error,
            // });
            reject(error);
          });
      });
    },
  },
};
</script>