<template>
  <div>

    <v-combobox outlined clearable :items="data" :value="value" :label="$t(model.title)" :error-messages="error"
      :disabled="disabled" @focus="fetchData()" @input="onInput($event)" chips>
    </v-combobox>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number, Object],
    values: Object,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      data: [],
    };
  },
  // created() {
  //   this.fetchData();
  // },
  methods: {
    async fetchData() {
      this.loading = true;
      let response = await this.$axios.get(this.model.api);
      this.loading = false;
      this.data = response.data.data;
    },
    onChange(e) {
      console.log(e);
    },
    onInput(e) {
      if (Array.isArray(e) && e.length > 0) {
        e = e[0];
      }
      if (e != null) e = String(e);
      this.$emit('input', e);
    }
  },
};
</script>