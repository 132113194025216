<template>
  <a-view-chip :value="value" :options="model.options"/>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: {
      type: Object,
      default: {},
    },
  },
};
</script>