<template>
  <div>
    <v-simple-table>
      <tbody>
        <tr v-for="(el, i) in model" :key="i">
          <td class="text-left">{{ el.title }}:</td>
          <td class="text-left">
            <b
              ><component
                v-if="el.type"
                :is="'a-view-' + el.type"
                :model="el"
                :values="data"
                :value="data[el.name]"
              ></component
            ></b>
          </td>
        </tr></tbody
    ></v-simple-table>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    model: Array,
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
    },
  },
};
</script>