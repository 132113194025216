const routes = [
    {
        path: '/index',
        name: 'index',
        meta: { auth: 1, title: "Главная" },
        component: () => {
            return import('@/views/index.vue')
        }
    },
    {
        path: '/tasks',
        name: 'tasks',
        meta: { auth: 1, title: "Задачи" },
        component: () => {
            return import('@/views/tasks.vue')
        }
    },
    {
        path: '/objects',
        name: 'objects',
        meta: { auth: 1, title: "Объекты" },
        component: () => {
            return import('@/views/objects.vue')
        }
    },
    {
        path: '/objects/:id',
        name: 'objects_view',
        meta: { auth: 1, title: "Объекты" },
        component: () => {
            return import('@/views/objectsView.vue')
        }
    },
    {
        path: '/rooms',
        name: 'rooms',
        meta: { auth: 1, title: "Студии" },
        component: () => {
            return import('@/views/rooms.vue')
        }
    },
    {
        path: '/rooms/:id',
        name: 'rooms_view',
        meta: { auth: 1, title: "Студии" },
        component: () => {
            return import('@/views/roomsView.vue')
        }
    },
    {
        path: '/buildings',
        name: 'buildings',
        meta: { auth: 1, title: "Строительство" },
        component: () => {
            return import('@/views/buildings.vue')
        }
    },
    {
        path: '/clients',
        name: 'clients',
        meta: { auth: 1, title: "Клиенты" },
        component: () => {
            return import('@/views/clients.vue')
        }
    },
    {
        path: '/clients/:id',
        name: 'clients_view',
        meta: { auth: 1, title: "Клиенты" },
        component: () => {
            return import('@/views/clientsView.vue')
        }
    },
    {
        path: '/sales',
        name: 'sales',
        meta: { auth: 1, title: "Продажи" },
        component: () => {
            return import('@/views/sales.vue')
        }
    },
    {
        path: '/finance',
        name: 'finance',
        meta: { auth: 1, title: "Финансы" },
        component: () => {
            return import('@/views/finance.vue')
        }
    },
    {
        path: '/sales',
        name: 'sales',
        meta: { auth: 1, title: "Продажи" },
        component: () => {
            return import('@/views/sales.vue')
        }
    },
    {
        path: '/ads',
        name: 'ads',
        meta: { auth: 1, title: "Объявления" },
        component: () => {
            return import('@/views/ads.vue')
        }
    },
    {
        path: '/vendors',
        name: 'vendors',
        meta: { auth: 1, title: "Поставщики" },
        component: () => {
            return import('@/views/vendors.vue')
        }
    },
    {
        path: '/records',
        name: 'records',
        meta: { auth: 1, title: "Разговоры" },
        component: () => {
            return import('@/views/records.vue')
        }
    },
    {
        path: '/info',
        name: 'info',
        meta: { auth: 1, title: "Инфо" },
        component: () => {
            return import('@/views/info.vue')
        }
    },
    {
        path: '/info-admin',
        name: 'info-admin',
        meta: { auth: 10, title: "Инфо" },
        component: () => {
            return import('@/views/infoAdmin.vue')
        }
    },
    {
        path: '/users',
        name: 'users',
        meta: { auth: 10, title: "Пользовтели" },
        component: () => {
            return import('@/views/users.vue')
        }
    },
];

export default {
    routes: routes
}