<template>
  <div class="pa-2">
    <v-card>
      <v-card-title>
        <span class="text_no_wrap"><slot name="title"></slot><v-spacer /></span>
        <v-spacer />
        <div class="text_no_wrap fullscreen_card__actions">
          <slot name="actions"></slot>
        </div>
      </v-card-title>
      <v-card-text
        :style="{ height: contentHeight + 'px' }"
        style="overflow: auto"
      >
        <slot></slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { staticFullHeight } from "./mixings";

export default {
  mixins: [staticFullHeight],
};
</script>

<style lang="scss">
.text_no_wrap {
  overflow: auto;
  white-space: nowrap;
}
.fullscreen_card__actions {
  .v-btn {
    margin-left: 5px;
  }
}
</style>