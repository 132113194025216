<template>
  <v-switch
    v-model="v"
    :disabled="disabled"
    :label="model.title"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    v: {
      get() {
        return this.value == 1 ? true : false;
      },
      set(v) {
        return this.$emit('input', v ? 1 : 0);
      }
    }
  }
};
</script>