<template>
  <v-row class="fill-height ma-0" align="center" justify="center">
    <v-card v-for="(el, key) in items" :key="key" width="200px" class="ma-2">
      <a
        :href="$root.config.imageUrl + el"
        target="_blank"
        @click.prevent="
          fullScr = el;
          fullImage = true;
        "
        ><v-img
          :src="$root.config.imageUrl + el + '?width=300&height=300'"
          height="200px"
        />
      </a>
    </v-card>
    <a-view-image-dialog v-model="fullImage" :src="fullScr" />
  </v-row>
</template>

<script>
export default {
  props: {
    value: [String, Number],
  },
  computed: {
    items: {
      get() {
        if (this.value) {
          try {
            return JSON.parse(this.value);
          } catch (error) {}
        }
        return [];
      },
    },
  },
  data() {
    return {
      fullImage: false,
      fullScr: "",
    };
  },
};
</script>