<template>
  <a-view-taskname :value="value" :values="values" :model="model" />
</template>

<script>
export default {
  props: {
    value: [String, Number],
    values: Object,
    model: Object,
  },
};
</script>