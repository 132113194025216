var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"f-table-col",style:({ width: _vm.width + 'px' })},[(
      [
        'id',
        'string',
        'ref-table',
        'login',
        'number',
        'phones',
        'text',
        'sparams' ].includes(_vm.filter.type)
    )?_c('a-input-table-search-string',{attrs:{"value":_vm.values[_vm.filter.name]},on:{"change":_vm.onInputElement}}):(['select'].includes(_vm.filter.type))?_c('a-input-table-search-select',{attrs:{"value":_vm.values[_vm.filter.name],"model":_vm.filter},on:{"change":_vm.onInputElement}}):(_vm.filter.type == 'selectapi')?_c('a-input-table-search-selectapi',{attrs:{"value":_vm.values[_vm.filter.name],"model":_vm.filter},on:{"change":_vm.onInputElement}}):(['date', 'datetime'].includes(_vm.filter.type))?_c('a-input-table-search-datetime',{attrs:{"value":_vm.values[_vm.filter.name]},on:{"input":_vm.onInputElement}}):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }